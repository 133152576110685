/* tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* font import */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;400;500;600;700&display=swap');

/*  */
/*  */

/* styles */
body {
  margin: 0;
  font-family: IBM Plex Mono, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*  */
/*  */

/* remove input btns */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

/*  */
/*  */

/* common */
Button, div, p, h1, h2, h3, h4, h5, h6, span {
  text-transform: uppercase !important;
}

p, h1, h2, h3, h4, h5, h6 {
  cursor: default !important;
}

/*  */
/*  */

/* scrollbar */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: #FF003C;
  border: 2px solid #F5F5F5;
}


/*  */
/*  */